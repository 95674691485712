import React, { useCallback, useState } from 'react'
import { ThemeProvider } from 'theme-ui'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import { MobileMenu } from '../../components/MobileMenu'
import { theme } from '../../theme'
import { Application, Content, GlobalStyle } from './styles'

const INITIAL_COPYRIGHT_YEAR = 2019

interface Props {
  children: React.ReactNode

  withMenu?: boolean
}

const GlobalLayout = React.memo<Props>(props => {
  const [isMobileMenuShown, setIsMobileMenuShown] = useState(false)

  const openBurgerMenu = useCallback(() => {
    setIsMobileMenuShown(true)
  }, [setIsMobileMenuShown])

  const closeBurgerMenu = useCallback(() => {
    setIsMobileMenuShown(false)
  }, [setIsMobileMenuShown])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Application>
        {isMobileMenuShown && <MobileMenu onClose={closeBurgerMenu} />}
        <Header
          sticky={true}
          onBurgerMenuClick={openBurgerMenu}
          withMenu={props.withMenu}
        />
        <Content>{props.children}</Content>
        <Footer initialCopyrightYear={INITIAL_COPYRIGHT_YEAR} />
      </Application>
    </ThemeProvider>
  )
})
GlobalLayout.displayName = 'MainLayout'

export { GlobalLayout }
