/** @jsx jsx */

import { Link } from 'gatsby'
import React from 'react'
import Headroom from 'react-headroom'
import { Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { LINKS } from '../../constants'
import logoSvg from '../../logo.svg'
import ringSvg from '../../ring.svg'
import { MenuItem } from '../Menu'

const BurgerMenuSlice = React.memo(() => {
  return <Flex sx={{ backgroundColor: 'lightText', height: '2px' }}></Flex>
})

const BurgerMenu = React.memo<{ className?: string; onClick?: () => void }>(
  props => {
    return (
      <Flex
        className={props.className}
        onClick={props.onClick}
        sx={{
          width: '26px',
          height: '21px',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <BurgerMenuSlice />
        <BurgerMenuSlice />
        <BurgerMenuSlice />
      </Flex>
    )
  }
)

const CrossLine = React.memo<{ className?: string }>(props => {
  return (
    <Flex
      className={props.className}
      sx={{ backgroundColor: 'lightText', height: '2px' }}
    ></Flex>
  )
})

const Cross = React.memo<{ className?: string; onClick?: () => void }>(
  props => {
    return (
      <Flex
        className={props.className}
        onClick={props.onClick}
        sx={{
          width: '26px',
          height: '26px',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CrossLine sx={{ transform: 'rotate(45deg) translateY(1px)' }} />
        <CrossLine sx={{ transform: 'rotate(-45deg) translateY(-1px)' }} />
      </Flex>
    )
  }
)

const Menu = React.memo(() => {
  return (
    <Flex
      as="ul"
      sx={{
        p: 0,
        mt: '-30px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {LINKS.map(link => (
        <Flex
          as="li"
          key={link.href}
          sx={{
            mr: 4,
            '&:last-of-type': {
              mr: 0,
            },
          }}
        >
          <MenuItem fontSize={1} {...link} />
        </Flex>
      ))}
    </Flex>
  )
})

interface HeaderProps {
  withMenu?: boolean
  isMenuOpen?: boolean
  onBurgerMenuClick?: () => void
  onCrossClick?: () => void

  sticky: boolean
}

const Header = React.memo<HeaderProps>(props => {
  return (
    <Headroom disable={!props.sticky} upTolerance={10} downTolerance={10}>
      <Flex
        as="header"
        sx={{
          pl: [4, 5],
          pb: '10px',
          alignItems: 'center',
          background: '#fff',
          zIndex: 1,
          borderBottom: '1px solid rgba(0, 0, 0, .08)',
        }}
      >
        {props.withMenu &&
          (props.isMenuOpen ? (
            <Cross
              onClick={props.onCrossClick}
              sx={{ mr: 3, display: ['flex', 'none'] }}
            />
          ) : (
            <BurgerMenu
              onClick={props.onBurgerMenuClick}
              sx={{ mr: 3, display: ['flex', 'none'] }}
            />
          ))}

        <Flex sx={{ flexGrow: 1, flexDirection: 'column' }}>
          <Flex
            sx={{
              flexGrow: 1,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Link sx={{ textDecoration: 'none' }} to="/">
              <Flex sx={{ alignItems: 'center' }}>
                <img
                  src={logoSvg}
                  alt="Logo"
                  sx={{ height: ['20px', '30px'], mr: [3, 4] }}
                />
                <span
                  sx={{
                    fontSize: [1, 4],
                    fontFamily: 'body',
                    color: 'logoText',
                  }}
                >
                  Marvin ROGER
                </span>
              </Flex>
            </Link>

            <img src={ringSvg} alt="Ring" sx={{ width: ['75px', '128px'] }} />
          </Flex>

          {props.withMenu && (
            <Flex sx={{ display: ['none', 'flex'] }}>
              <Menu />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Headroom>
  )
})
export { Header }
