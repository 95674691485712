/** @jsx jsx */
import React, { useEffect } from 'react'
import { Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { LINKS } from '../constants'
import { GeometricAnimation } from './GeometricAnimation'
import { Header } from './Header'
import { MenuItem } from './Menu'

interface MobileMenuProps {
  onClose: () => void
}

const MobileMenu = React.memo<MobileMenuProps>(props => {
  useEffect(() => {
    const root = document.documentElement || document.body
    const initialScroll = root.scrollTop
    root.style.overflow = 'hidden'
    root.scrollTop = 0

    return function cleanup() {
      root.style.overflow = 'auto'
      root.scrollTop = initialScroll
    }
  }, [])

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: 'background',
        zIndex: 2,
      }}
    >
      <Header
        sticky={false}
        onCrossClick={props.onClose}
        withMenu={true}
        isMenuOpen={true}
      />

      <Flex
        sx={{
          mt: 4,
          backgroundColor: 'detail',
        }}
      >
        <GeometricAnimation sx={{ flexGrow: 1 }} />
      </Flex>

      <Flex
        sx={{
          flexGrow: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          p: 4,
        }}
      >
        <Flex
          as="ul"
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {LINKS.map(link => (
            <Flex
              as="li"
              key={link.href}
              sx={{
                mt: 4,
                '&:first-of-type': {
                  mt: 0,
                },
              }}
            >
              <MenuItem fontSize={2} {...link} />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
})
MobileMenu.displayName = 'MobileMenu'

export { MobileMenu }
