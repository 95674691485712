/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { jsx } from 'theme-ui'
import Zdog from 'zdog'

interface Props {
  className?: string
}

const GeometricAnimation = React.memo<Props>(props => {
  const canvasEl = useRef<SVGSVGElement>(null)

  useEffect(() => {
    const illo = new Zdog.Illustration({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      element: canvasEl.current!,
      zoom: 3,
    })

    const buildCircle = (z: number) =>
      new Zdog.Ellipse({
        addTo: illo,
        diameter: 20,
        translate: { z },
        stroke: 5,
        color: '#FF4D6E',
      })

    const buildSquare = (z: number) =>
      new Zdog.Rect({
        addTo: illo,
        width: 20,
        height: 20,
        translate: { z },
        stroke: 3,
        color: '#FF6824',
        fill: true,
      })

    const buildTriangle = (z: number) =>
      new Zdog.Shape({
        addTo: illo,
        path: [
          { x: 0, y: -8 },
          { x: 8, y: 8 },
          { x: -8, y: 8 },
        ],
        stroke: 3,
        translate: { z },
      })

    buildTriangle(40)
    buildCircle(20)
    buildSquare(0)
    buildCircle(-20)
    buildTriangle(-40)

    function animate() {
      illo.rotate.y += 0.03
      illo.updateRenderGraph()
      requestAnimationFrame(animate)
    }

    animate()
  }, [props, canvasEl])

  return (
    <svg width="608" height="118" ref={canvasEl} className={props.className} />
  )
})

export { GeometricAnimation }
