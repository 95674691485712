import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

interface SEOProps {
  title: string
  description?: string
  location: Location
  image?: {
    childImageSharp: {
      seo: {
        images: {
          fallback: {
            src: string
          }
        }
      }
    }
  }
  article?: boolean
}

export const SEO = React.memo<SEOProps>(
  ({ title, description, image, location, article }) => {
    const data = useStaticQuery(graphql`
      fragment SEOImage on ImageSharp {
        seo: gatsbyImageData(
          layout: FIXED
          width: 950
          height: 492
          transformOptions: { cropFocus: CENTER }
          quality: 100
        )
      }

      query SEOQuery {
        site {
          siteMetadata {
            titleTemplate
            defaultDescription
            siteUrl
            social {
              github
            }
          }
        }

        defaultImage: file(
          sourceInstanceName: { eq: "misc" }
          relativePath: { eq: "default-image.png" }
        ) {
          childImageSharp {
            ...SEOImage
          }
        }
      }
    `)
    const { siteMetadata: metadata } = data.site

    const imagePath = image
      ? image.childImageSharp.seo.images.fallback.src
      : data.defaultImage.childImageSharp.seo.images.fallback.src
    const seo = {
      title: title || metadata.defaultTitle,
      description: description || metadata.defaultDescription,
      imageUrl: `${metadata.siteUrl}${imagePath}`,
      url: `${metadata.siteUrl}${location.pathname}`,
    }

    return (
      <>
        <Helmet title={seo.title} titleTemplate={metadata.titleTemplate}>
          <html lang="fr" />

          <meta name="description" content={seo.description} />

          {/* Open graph */}
          <meta property="og:url" content={seo.url} />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.imageUrl} />
          {article && <meta property="og:type" content="article" />}

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.imageUrl} />

          <meta name="theme-color" content="#FF4D6E"></meta>
        </Helmet>
      </>
    )
  }
)
SEO.displayName = 'SEO'
