/** @jsx jsx */

import React, { useMemo } from 'react'
import { Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import { gradientMixin, textGradientMixin } from '../../components/TextGradient'
import emailSvg from './email.svg'
import githubSvg from './github.svg'

const FooterItem = React.memo(
  (props: {
    href: string
    children: React.ReactNode
    className?: string
    ariaLabel?: string
  }) => {
    return (
      <a
        href={props.href}
        className={props.className}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={props.ariaLabel}
        sx={{
          fontSize: 0,
          fontFamily: 'body',
          color: 'text',
          textDecoration: 'none',
          '&:hover': textGradientMixin,
        }}
      >
        {props.children}
      </a>
    )
  }
)

const Icon = React.memo((props: { src: string; className?: string }) => {
  return (
    <div
      className={props.className}
      sx={{
        maskImage: `url('${props.src}')`,
        backgroundColor: 'text',
        '&:hover': gradientMixin,
      }}
    />
  )
})

const Footer = React.memo<{ initialCopyrightYear: number }>(props => {
  const copyrightPeriod = useMemo(() => {
    const currentYear = new Date().getFullYear()

    if (currentYear === props.initialCopyrightYear) {
      return currentYear.toString()
    }

    return `${props.initialCopyrightYear} - ${currentYear}`
  }, [props.initialCopyrightYear])

  return (
    <Flex
      as="footer"
      sx={{
        flexDirection: 'column',
        alignItems: 'stretch',
        px: [4, 5],
        mt: [4, 5],
      }}
    >
      <Flex
        sx={{
          flexDirection: ['column', 'row'],
          justifyContent: ['center', 'space-between'],
          alignItems: 'center',
          borderTop: '1px solid',
          borderTopColor: '#D8D8D8',
          flexGrow: 1,
          py: 3,
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <FooterItem
            href="https://github.com/marvinroger"
            ariaLabel="Voir mon GitHub"
            sx={{ mr: [3, 4] }}
          >
            <Icon src={githubSvg} sx={{ height: '26px', width: '26px' }} />
          </FooterItem>

          <FooterItem
            href="mailto:bonjour+web@marvinroger.fr"
            ariaLabel="M'envoyer un e-mail"
            sx={{ mr: [3, 4] }}
          >
            <Icon src={emailSvg} sx={{ height: '26px', width: '26px' }} />
          </FooterItem>

          <FooterItem href="/cv.pdf">C.V.</FooterItem>
        </Flex>

        <Flex
          sx={{
            mt: [2, 0],
            fontSize: 0,
            fontFamily: 'body',
            color: 'lightText',
          }}
        >
          Copyright © {copyrightPeriod} Marvin ROGER
        </Flex>
      </Flex>
    </Flex>
  )
})

export { Footer }
