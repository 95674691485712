/** @jsx jsx */
import { css, Global } from '@emotion/react'
import { motion } from 'framer-motion'
import React from 'react'
import { Flex } from 'theme-ui'
import { jsx } from 'theme-ui'
import productSansMediumWoff from '../../fonts/product-sans/ProductSans-Medium.woff'
import productSansMediumWoff2 from '../../fonts/product-sans/ProductSans-Medium.woff2'
import productSansMediumRegularWoff from '../../fonts/product-sans/ProductSans-Regular.woff'
import productSansMediumRegularWoff2 from '../../fonts/product-sans/ProductSans-Regular.woff2'
import { useTheme } from '../../theme'

const GlobalStyle = React.memo(() => {
  const { theme } = useTheme()

  return (
    <Global
      styles={() => css`
        * {
          box-sizing: border-box;
        }

        @font-face {
          font-family: 'Product Sans';
          src: url(${productSansMediumWoff2}) format('woff2'),
            url(${productSansMediumWoff}) format('woff');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Product Sans';
          src: url(${productSansMediumRegularWoff2}) format('woff2'),
            url(${productSansMediumRegularWoff}) format('woff');
          font-weight: normal;
          font-style: normal;
        }

        ::selection {
          background: ${theme.colors.selection};
        }
        ::-moz-selection {
          background: ${theme.colors.selection};
        }

        body {
          margin: 0;
          overflow-x: hidden;
        }
      `}
    />
  )
})

interface ApplicationProps {
  children: React.ReactNode
}

const AnimatedFlex = motion(Flex)

const Application = React.memo<ApplicationProps>(props => (
  <AnimatedFlex
    initial="hidden"
    animate="visible"
    variants={{
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }}
    sx={{
      minHeight: '100vh',
      flexDirection: 'column',
      backgroundColor: 'background',
    }}
  >
    {props.children}
  </AnimatedFlex>
))

const Content = React.memo<ApplicationProps>(props => (
  <Flex
    as="main"
    sx={{
      flexGrow: 1,
      flexDirection: 'column',
    }}
  >
    {props.children}
  </Flex>
))

export { Application, Content, GlobalStyle }
