import { useThemeUI, type Theme, type ThemeUIContextValue } from 'theme-ui'

const makeTheme = <T extends Theme>(t: T) => t

export const theme = makeTheme({
  fonts: {
    body: '"Product Sans", sans-serif',
    heading: '"Product Sans", sans-serif',
  },
  fontWeights: {
    body: 'normal',
    heading: 500,
  },
  fontSizes: [16, 20, 24, 28, 32, 45],
  letterSpacings: ['0.4px', '0.6px', '0.9px'],
  lineHeights: ['1', '1.4', '1.8'],
  colors: {
    text: '#000',
    text2: '#333',
    logoText: '#4D4D4D',
    lightText: '#8A8A8A',
    background: '#FFF',
    primary1: '#FF4D6E',
    primary2: '#FF6824',
    detail: '#FFF1EA',
    selection: '#FFB7B7',
  },
  space: [0, 4, 8, 16, 32, 64],
})

export type ExactTheme = typeof theme

interface ExactContextValue extends Omit<ThemeUIContextValue, 'theme'> {
  theme: ExactTheme
}

export const useTheme = (useThemeUI as unknown) as () => ExactContextValue
