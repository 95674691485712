/** @jsx jsx */
import { Link } from 'gatsby'
import React from 'react'
import { jsx } from 'theme-ui'
import { textGradientMixin } from './TextGradient'

interface MenuItemProps {
  href: string
  text: string
  fontSize: string | number | (string | number)[]
}

const MenuItem = React.memo<MenuItemProps>(props => {
  return (
    <Link
      to={props.href}
      activeClassName="active"
      sx={{
        fontSize: props.fontSize,
        fontFamily: 'heading',
        fontWeight: 'heading',
        color: 'text',
        textDecoration: 'none',
        '&:hover': textGradientMixin,
        '&.active': textGradientMixin,
      }}
    >
      {props.text}
    </Link>
  )
})
MenuItem.displayName = 'MenuItem'

export { MenuItem }
